<template>
    <div class="card p-3 max-w-670">
        <div class="p-7 space-y-5">
            <div>
                <label class="font-semibold">Judul Skripsi</label><br>
                <input class="form-control" v-model="data.title" type="text" value="" autocomplete="off" required disabled>
            </div>

            <div>
                <label class="font-semibold">Tipe Skripsi</label><br>
                <input class="form-control" v-model="data.type" type="text" value="" autocomplete="off" required disabled>
            </div>

            <div v-for="(mentor, index) in data.mentors" :key="mentor.id">
                <label class="font-semibold m-0">Pembimbing {{ index + 1 }}</label><br>
                <p class="m-0">{{ mentor.fullname }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        computed: {
            ...mapState('skripsi', { data: 'data', loading: 'loading' })
        }
    }
</script>
